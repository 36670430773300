<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="meditation.png">
    <template v-slot:title> Practice guided meditation</template>
    <template v-slot:subtitle>
      When you are feeling distress meditation can help you gain a sense of
      calm, peace and balance that can benefit. Meditation can help you get
      through your day more calmly and may help you manage thoughts of suicide.
    </template>
  </Banner>

  <section class="tabs-sect">
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6"></div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Body Scan Meditation
      </div>
      <p class="mb-6">
        Release Tension with this Targeted Meditation Technique.
      </p>
      <div class="w-full aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/MCKJ_WZRjMQ"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Awareness of thought Meditation
      </div>
      <p class="mb-6">
        A Meditation on Observing Thoughts, Non-Judgmentally and gaining freedom
        from your thoughts.
      </p>
      <div class="w-full aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/lXrSws7l1LI"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Loving kindness meditation
      </div>
      <p class="mb-6">
        Radical act of love - strengthen feelings of kindness and connection
        toward yourself.
      </p>
      <div class="w-full aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/v4dAHosy9-0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Walking Meditation
      </div>
      <p class="mb-6">
        A way to deepen our connection with our body and the earth and bring a
        relax focus.
      </p>
      <div class="w-full aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/0W-Ui_nKAZM"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Breathing meditation
      </div>
      <p class="mb-6">
        A way to focus your attention on your breathing — to its natural rhythm
        and flow.
      </p>
      <div class="w-full aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/Qu8KZMQTNUA"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </section>
  <FooterCard
    img="help-for-someone-else-braid.png"
    text="View Tools & resources"
    name="ToolsAndResources"
  >
    <template v-slot:header>Try another coping strategy</template>
    <template v-slot:content>
      Different coping strategies resonate with different people.
      <br />
      Discover more coping strategies and add to your toolbox.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "PracticeGuidedMeditation",
  components: {
    Banner,
    FooterCard,
  },
};
</script>
